import React, { Component } from 'react'
import { connect } from 'react-redux'
import Idle from './Idle'
import Login from './Login'
import RideNew from './RideNew'
import MachineSelect from './MachineSelect'
import Modal from './Modal'
import { clearMachines, getMachines } from '../actions'

const mapStateToProps = ({ currentUser, machineConfig, idle }) => {
  return {
    currentUser,
    machineConfig,
    idle,
  }
}
const mapDispatchToProps = {
  clearMachines,
  getMachines,
}

// @todo can we rename this component?
class App extends Component {
  componentDidMount () {
    if (this.props.currentUser._id) {
      this.updateMachines()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.currentUser._id && this.props.currentUser._id !== prevProps.currentUser._id) {
      this.updateMachines()
    }
  }

  updateMachines = () => {
    // Get all machines for all groups the user belongs to
    this.props.clearMachines()
    for (let membership of this.props.currentUser.memberships) {
      this.props.getMachines(membership.group)
    }
  }

  render () {
    let content
    if (this.props.idle) {
      content = <Idle />
    }
    else {
      if (this.props.currentUser._id) {
        if (this.props.machineConfig && this.props.machineConfig.pairedAt) {
          content = <RideNew />
        }
        else {
          content = <MachineSelect />
        }
      }
      else {
        content = <Login />
      }
    }

    return (
      <main className="appWrapper">
        <Modal />
        {content}
      </main>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
